// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  group: getIcon('ic_group'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  folder: getIcon('ic_folder'),


  payees: getIcon('ic_payees'),
  customers: getIcon('ic_customers'),
  business: getIcon('ic_business'),
  pattern: getIcon('ic_pattern'),
  transactions: getIcon('ic_transactions'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // { title: 'ecommerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // USER
      { title: 'Customers', path: PATH_DASHBOARD.customer.list, icon: ICONS.customers },
      { title: 'Business', path: PATH_DASHBOARD.business.list, icon: ICONS.business },
      { title: 'Accounts', path: PATH_DASHBOARD.accounts.list, icon: ICONS.banking },
      { title: 'Payees', path: PATH_DASHBOARD.payees.list, icon: ICONS.payees },
      { title: 'TM & Fraud', path: PATH_DASHBOARD.compliance.root, icon: ICONS.pattern },
      // exports 
      { title: 'File Manager', path: PATH_DASHBOARD.files.root, icon: ICONS.folder },
      { title: 'Transactions', path: PATH_DASHBOARD.transactions.root, icon: ICONS.transactions },
      // E-COMMERCE
      // {
      //   title: 'ecommerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //   ],
      // },

      // INVOICE
      // {
      //   title: 'invoice',
      //   path: PATH_DASHBOARD.invoice.root,
      //   icon: ICONS.invoice,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.invoice.list },
      //     { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.invoice.new },
      //     { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
      //   ],
      // },

      // BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.blog.new },
      //   ],
      // },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      // {
      //   title: 'mail',
      //   path: PATH_DASHBOARD.mail.root,
      //   icon: ICONS.mail,
      //   info: <Label color="error">+32</Label>,
      // },
      { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
      // { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      // { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
    ],
  },

  // DEMO MENU STATES
  {
    subheader: 'Other',
    items: [
      // External
      {
        title: 'External Links',
        // path: PATH_DASHBOARD.user.root,
        icon: ICONS.invoice,
        children: [
          {
            title: 'Egaliti AML/CTF Policy',
            path: 'https://drive.google.com/file/d/1Bz4IUZ1JFx31ES3foWK7W_prOj_3p-OZ/view?usp=sharing',
          },
          {
            title: 'Egaliti AML Risk Assessment',
            path: 'https://drive.google.com/drive/u/0/folders/1IeUME628Uy0uFrLRckdf0vt2TZ36erFM',
          },
          {
            title: 'Egaliti Policy Library',
            path: 'https://drive.google.com/drive/folders/1PacvXoDXsJG7ZKlUu2xa6vTklcwzuP57?usp=sharing',
          },
          {
            title: 'Access Comply Advantage',
            path: 'https://complyadvantage-app-euw1.auth.eu-west-1.amazoncognito.com/login?client_id=234l01qbmm69745r3uf75h44pl&redirect_uri=https%3A%2F%2Fapp.complyadvantage.com%2F.ambassador%2Foauth2%2Fredirection-endpoint&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&state=6500c8e221084e074278a019050b6dd5bb46b6e9ddb6d918f1c9af491899a9d6%3Ahttp%3A%2F%2Fapp.complyadvantage.com%2F',
          },
          { title: 'Access W2', path: 'https://portal.w2globaldata.com/Account/Login?ReturnUrl=%2F' },
          {
            title: 'Access Lexis Diligence',
            path: 'https://signin.lexisnexis.com/lnaccess/app/signin?back=https%3A%2F%2Fwww.diligence.lexisnexis.com&aci=dd',
          },
          { title: 'Access Open Corporates', path: 'https://opencorporates.com/' },
          { title: 'Access CreditSafe', path: 'https://mylogin.creditsafe.com/en-gb' },
          {
            title: 'Access OFAC Sanctions',
            path: 'https://home.treasury.gov/policy-issues/financial-sanctions/sanctions-list-search-tool',
          },
          { title: 'Access FCA Handbook', path: 'https://www.handbook.fca.org.uk/handbook' },
          { title: 'Access FCA SCA', path: 'https://www.fca.org.uk/firms/strong-customer-authentication' },
          {
            title: 'Study RB compliance firewall',
            path: 'https://docs.railsbank.com/docs/guides/ZG9jOjI4MjEyNjA4-compliance-firewall-use-case',
          },
          { title: 'Search FCA register', path: 'https://register.fca.org.uk/s/' },
        ],
      },
    ],
  },
];

export default navConfig;
