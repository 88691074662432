import { createSlice } from '@reduxjs/toolkit';
// utils
import API from '../../utils/apiConfig';
import { get } from '../../utils/server';
///
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: null,
  transactions: [],
  currentPage: 1,
  hasMore: true,
  pages: 0
};

const slice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.transactions = []
      state.pages = 0
    },

    // Get Customer Account Transactions
    getTransactionsSuccess(state, action) {
      state.isLoading = false;
      state.transactions = action.payload.transactions
      state.pages = action.payload.pages
    },
    resetTransactions(state, action) {
      state.isLoading = false;
      state.transactions = []
      state.pages = 0
    }
  },
});

// Reducer
export default slice.reducer;

export const {
  resetTransactions
} = slice.actions;
// ----------------------------------------------------------------------

//
export function getCustomerTransactions(accountId, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    // 
    try {
      const query = { page_number: params.page, item_per_page: params.itemsPerPage, status: params.status, direction: params.direction, search: params.searchTerm };
      Object.keys(query).forEach(key => {
        if (query[key] === undefined || query[key] === null || query[key] === "all") {
          delete query[key];
        }
      });

      const path = API.GET_TRANSACTIONS(accountId, query)
      const response = await get(path);
      dispatch(
        slice.actions.getTransactionsSuccess({
          transactions: response.transactions,
          pages: response.number_of_pages,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBusinessTransactions(accountId, businessId, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = { page_number: params.page, item_per_page: params.itemsPerPage, status: params.status, direction: params.direction, search: params.searchTerm };
      Object.keys(query).forEach(key => {
        if (query[key] === undefined || query[key] === null || query[key] === "all") {
          delete query[key];
        }
      });
      const response = await get(API.GET_TRANSACTIONS(accountId, query));
      dispatch(
        slice.actions.getTransactionsSuccess({
          transactions: response.transactions,
          pages: response.number_of_pages,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getTransactions(query) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const path = API.GET_ALL_TRANSACTiONS(query)
      const response = await get(path);
      dispatch(
        slice.actions.getTransactionsSuccess({
          transactions: response.list,
          pages: response?.pagination?.total_pages,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}